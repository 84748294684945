import React from "react"
import {Container, Row, Col} from 'reactstrap'

import Layout from '../../components/layout'
import Cloud from '../../components/cloud'

// TODO someday build team members dynamically :)

export default ({ data }) => (
    <Layout title="Michael Hässig">
        <Cloud title="Michael Hässig"/>

        <Container className="bg-white member">
            <Row>
                <Col md="4">
                    <img className="img-fluid" src="/assets/michaelhaessig.png" alt="michael haessig"/>
                </Col>
                <Col md="8">
                    <h5 className="member-role">Firmengründer</h5>
                    <p>Michael Hässig ist gelernter Informatiker Applikationsentwickler. Er begann bereits im Alter von 15 Jahren mit dem Programmieren.  Michael arbeitet seit 2010 als Informatiker. In dieser Zeit erarbeitete er sich ein breites Know-How in verschiedenen Bereichen der Informatik. Zu seinen Kompetenzen zählen folgende Bereiche.</p>
                    <ul>
                        <li>Software Architektur Design</li>
                        <li>Software Performance Analyse</li>
                        <li>Web Applikations- Design</li>
                        <li>Web Applikations- Sicherheit</li>
                        <li>Interaktives Website Design</li>
                        <li>Server Infrastruktur Design</li>
                    </ul>
                    <p>Mit einer Leidenschaft für seinen Beruf ist das Programmieren gleichzeitig ein Hobby. Er besitzt Kenntnisse in den folgenden Programmiersprachen.</p>
                    <ul>
                        <li>C / C++</li>
                        <li>C#</li>
                        <li>Python</li>
                        <li>PHP</li>
                        <li>JavaScript</li>
                        <li>TypeScript</li>
                        <li>Java</li>
                        <li>Objective C</li>
                    </ul>
                    <p><a href="https://www.michaelhaessig.com">michaelhaessig.com</a></p>


                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="member-timeline">

                        <h3>Ausbildung</h3>

                        <ul className="cbp_tmtimeline">
                            <li>
                                <time className="cbp_tmtime">
                                    <span>2010 - 2014</span> <span></span></time>
                                <div className="cbp_tmicon">
                                    <i className="fas fa-graduation-cap"></i>
                                </div>
                                <div className="cbp_tmlabel">
                                    <h2>Berufslehre Informatiker EFZ Applikationsentwickler</h2>
                                </div>
                            </li>
                            <li>
                                <time className="cbp_tmtime" >
                                    <span>2016 - 2019</span> <span></span></time>
                                <div className="cbp_tmicon">
                                    <i className="fas fa-graduation-cap"></i>
                                </div>
                                <div className="cbp_tmlabel">
                                    <h2>Höhere Fachschule Informatik</h2>
                                </div>
                            </li>
                        </ul>

                    </div>
                </Col>
            </Row>
        </Container>
    </Layout>
)

